import React from 'react';

export type ICursorContext = {
  size: 'small' | 'medium' | 'large';
  setSize: (size: 'small' | 'medium' | 'large') => void;
};

interface CursorContextProviderProps {
  children: React.ReactNode;
}

const CursorContext = React.createContext<ICursorContext>({} as ICursorContext);
CursorContext.displayName = 'CursorContext';

export function CursorContextProvider({ children }: CursorContextProviderProps) {
  const [size, setSize] = React.useState<'small' | 'medium' | 'large'>('small');

  const ctx = {
    size,
    setSize,
  };

  return <CursorContext.Provider value={ctx}>{children}</CursorContext.Provider>;
}

export const useCursorContext = () => React.useContext(CursorContext);
