import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import { setTimeout, clearTimeout } from 'requestanimationframe-timer';

type HocProps = {
  delay: number;
  // [key: string]: any;
} & TransitionProps;
//https://stackoverflow.com/questions/64051822/react-hoc-with-typescript-and-react-hooks
const withDelayTransitionRender = (WrappedComponent: typeof Transition) =>
  function Comp(props: HocProps) {
    const [shouldRender, setShouldRender] = React.useState<boolean>(!props.delay);

    React.useEffect(() => {
      const renderTimeout = setTimeout(() => setShouldRender(true), props.delay);

      return () => {
        if (renderTimeout) {
          clearTimeout(renderTimeout);
        }
      };
    });

    return shouldRender || typeof window === `undefined` ? <WrappedComponent {...props} /> : null;
  };

export default withDelayTransitionRender;
