export const onExit = ({
  node,
  inTransition,
  exitTrigger,
  entryProps,
  exitProps,
  triggerResolve,
  e,
}: // preventScrollJump = true,
any) => {
  if (!inTransition) return;
  const { trigger: removed, ...exitPropsTrimmed } = exitProps;
  console.log(node);
  triggerResolve.exit({
    ...exitPropsTrimmed,
    node,
  });

  return (
    exitTrigger &&
    typeof exitTrigger === 'function' &&
    exitTrigger({
      entry: entryProps,
      exit: exitProps,
      node,
      e,
    })
  );
};
