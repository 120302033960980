import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { CookiesProvider } from 'react-cookie';
import GlobalStyles from './src/styles/GlobalStyles';
import Typography from './src/styles/Typography';
import 'normalize.css/normalize.css';
import './src/styles/global.css';
import { TransitionContextProvider } from './src/context/TransitionContext';
import TransitionLayout from './src/components/TransitionLayout';
import { CursorContextProvider } from './src/context/CursorContext';

//animations
import TransitionHandler from './src/components/animations/TransitionHandler';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <CookiesProvider>
        <CursorContextProvider>{element}</CursorContextProvider>
      </CookiesProvider>
    </>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  // one way to  persist container for animations is to use props and conditionally render
  // that container based on route from props
  return (
    <>
      <TransitionContextProvider>
        <TransitionHandler>
          <TransitionLayout>{element}</TransitionLayout>
        </TransitionHandler>
      </TransitionContextProvider>
    </>
  );
};

export const shouldUpdateScroll = () => !(window as any).__tl_inTransition;
