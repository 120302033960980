import { setTimeout } from 'requestanimationframe-timer';

export const onEnter = ({
  node,
  inTransition,
  entryTrigger,
  entryProps,
  exitProps,
  triggerResolve,
  pathname,
  preventScrollJump,
  hash,
  locationKey,
  entryProps: { delay = 0 },
  appearAfter = 0,
  e,
}: any) => {
  if (inTransition && !preventScrollJump) {
    setTimeout(() => {
      let scrollTo = [0, 0];
      // handle hashes that link to ID's
      // for ex /page-2#heading-section
      if (hash) {
        const hashElement = document.getElementById(hash);

        if (hashElement) {
          const clientOffsetTop = hashElement.offsetTop;
          scrollTo = [0, clientOffsetTop];
        }
      }

      window.scrollTo({
        top: scrollTo[0],
        left: scrollTo[1],
      });
    }, appearAfter);
  } else if (!inTransition) {
    // If session storage fails due to cookies being disabled,
    // scroll to the top after every navigation
    let position = [0, 0];
    try {
      const storageKey = `@@scroll|${pathname}|${locationKey}`;
      const yPos = sessionStorage.getItem(storageKey);
      const y = yPos ? JSON.parse(yPos) || 0 : 0;

      position = [0, y];
    } catch (e) {
      console.warn(`Unable to save state in sessionStorage; sessionStorage is not available.`);
    } finally {
      window.scrollTo({
        top: position[0],
        left: position[1],
      });
    }
  }

  if (!inTransition) return;

  const { trigger: removed, ...entryPropsTrimmed } = entryProps;

  const timeout = appearAfter + delay;

  const visiblePromise = new Promise(resolve => {
    setTimeout(() => resolve(true), timeout);
  });

  triggerResolve.entry({
    ...entryPropsTrimmed,
    visible: visiblePromise,
    node,
  });

  entryTrigger &&
    typeof entryTrigger === 'function' &&
    entryTrigger({
      entry: entryProps,
      exit: exitProps,
      node,
      e,
    });
};
