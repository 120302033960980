import React from 'react';
import gsap from 'gsap';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import { useTransitionContext } from 'context/TransitionContext';

type Props = {
  children: React.ReactNode;
};
function TransitionLayout({ children }: Props) {
  const [displayChildren, setDisplayChildren] = React.useState(children);
  const { timeline, background } = useTransitionContext();
  const el = React.useRef(null);

  useIsomorphicLayoutEffect(() => {
    if (children !== displayChildren) {
      if (timeline.duration() === 0) {
        // there are no outro animations, so immediately transition
        setDisplayChildren(children);
      } else {
        timeline.play().then(() => {
          // outro complete so reset to an empty paused timeline
          timeline.seek(0).pause().clear();
          setDisplayChildren(children);
        });
      }
    }
  }, [children]);

  // useIsomorphicLayoutEffect(() => {
  //   gsap.to(el.current, {
  //     background,
  //     duration: 1,
  //   });
  // }, [background]);

  return <div ref={el}>{displayChildren}</div>;
}

export default TransitionLayout;
