import React from 'react';
import { setTimeout, clearTimeout } from 'requestanimationframe-timer';

type TransitionRendererProps = {
  appearAfter: number;
  delay?: number;
  transitionStatus: any;
  mount: any;
  entryZindex: any;
  exitZindex: any;
  transitionState: any;
  children: React.ReactNode;
  injectPageProps?: any;
};

const TransitionRenderer = React.memo<TransitionRendererProps>(
  ({ delay = 0, appearAfter = 0, transitionStatus, children, mount, entryZindex, exitZindex }) => {
    const [shouldBeVisible, setShouldBeVisible] = React.useState(!appearAfter);

    React.useEffect(() => {
      const timeout = appearAfter + delay;
      const appearTimeout = setTimeout(() => setShouldBeVisible(true), timeout);
      return () => {
        if (appearTimeout) {
          clearTimeout(appearTimeout);
        }
      };
    });
    return (
      <div
        className={`tl-wrapper ${
          mount ? 'tl-wrapper--mount' : 'tl-wrapper--unmount'
        } tl-wrapper-status--${transitionStatus}`}
        style={{
          zIndex: mount ? entryZindex : exitZindex,
          opacity: shouldBeVisible ? 1 : 0,
        }}
      >
        {children}
      </div>
    );
  }
);

TransitionRenderer.displayName = 'TransitionRenderer';

export default TransitionRenderer;
