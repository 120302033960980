import React from 'react';
import gsap from 'gsap';

// https://github.com/TylerBarnes/gatsby-plugin-transition-link/blob/master/src/context/InternalProvider.js
type TransitionState = {
  inTransition: boolean;
  // exit
  exitDelay: number;
  exitLength: number;
  exitState: any;
  exitProps: any;
  exitTrigger: false;
  // entry
  entryDelay: 0;
  entryLength: 0;
  entryState: any;
  entryProps: any;
  entryTrigger: false;
  triggerResolve: any;
  appearAfter: number;
  e: any;
};
export type ITransitionContext = {
  timeline: GSAPTimeline;
  background: string;
  setTimeline: React.Dispatch<React.SetStateAction<gsap.core.Timeline>>;
  setBackground: React.Dispatch<React.SetStateAction<string>>;
} & TransitionState & { updateTransitionState: any };

// export type PageContextType = {
//   pageContext: ITransitionContext;
// };

interface PageContextProviderProps {
  children: React.ReactNode;
  pageContextProp?: ITransitionContext;
}

const TransitionContext = React.createContext<ITransitionContext>({} as ITransitionContext);
TransitionContext.displayName = 'TransitionContext';

export function TransitionContextProvider({ children }: PageContextProviderProps) {
  const [timeline, setTimeline] = React.useState(() => gsap.timeline({ paused: true }));
  const [background, setBackground] = React.useState('white');
  const [transitionState, setTransitionState] = React.useState<TransitionState>({
    inTransition: false,
    // exit
    exitDelay: 0,
    exitLength: 0,
    exitState: {},
    exitProps: {},
    exitTrigger: false,
    // entry
    entryDelay: 0,
    entryLength: 0,
    entryState: {},
    entryProps: {},
    entryTrigger: false,
    triggerResolve: {
      entry: () => null,
      exit: () => null,
    },
    appearAfter: 0,
    e: false,
  });

  const ctx = {
    timeline,
    background,
    setTimeline,
    setBackground,
    ...transitionState,
    updateTransitionState: setTransitionState,
  };

  return <TransitionContext.Provider value={ctx}>{children}</TransitionContext.Provider>;
}

export const useTransitionContext = () => React.useContext(TransitionContext);
