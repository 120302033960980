import React from 'react';
import { Location } from '@reach/router';
import { Transition, TransitionGroup } from 'react-transition-group';
import withDelayTransitionRender from './withDelayTransitionRender';
import TransitionRenderer from './TransitionRenderer';
import { useTransitionContext } from 'context/TransitionContext';
import getMs from '@utils/getMs';
import { onEnter } from './onEnter';
import { onExit } from './onExit';

const DelayedTransition = withDelayTransitionRender(Transition);

// this component will get provider props and render TransitionRenderer
function TransitionHandler({ children }: { children: React.ReactNode }) {
  // get transition context
  const {
    exitDelay,
    exitLength,
    exitState,
    entryDelay,
    entryLength,
    entryState,
    entryTrigger,
    entryProps,
    exitTrigger,
    exitProps,
    inTransition,
    updateTransitionState,
    triggerResolve,
    appearAfter,
    // preventScrollJump,
    // hash,
    e,
  } = useTransitionContext();

  return (
    <Location>
      {({ location }) => {
        const { pathname, key: locationKey } = location;

        return (
          <div className="tl-edges">
            <TransitionGroup component={null}>
              <DelayedTransition
                key={pathname} // we're using seconds but transitiongroup uses ms
                delay={getMs(entryDelay)}
                timeout={{
                  enter: getMs(entryLength),
                  exit: getMs(exitLength),
                }}
                onEnter={node =>
                  !!node &&
                  // !window.__tl_back_button_pressed &&
                  onEnter({
                    node,
                    // action,
                    inTransition,
                    entryTrigger,
                    entryProps,
                    exitProps,
                    pathname,
                    updateTransitionState,
                    triggerResolve,
                    // preventScrollJump,
                    // hash,
                    locationKey,
                    appearAfter: getMs(appearAfter),
                    e,
                  })
                }
                onExit={node =>
                  !!node &&
                  // !window.__tl_back_button_pressed &&
                  onExit({
                    node,
                    inTransition,
                    exitTrigger,
                    entryProps,
                    exitProps,
                    triggerResolve,
                    e,
                  })
                }
              >
                {transitionStatus => {
                  const mount = transitionStatus === 'entering' || transitionStatus === 'entered';

                  const states = {
                    entry: {
                      state: entryState,
                      delay: entryDelay,
                      length: entryLength,
                    },
                    exit: {
                      state: exitState,
                      delay: exitDelay,
                      length: exitLength,
                    },
                  };

                  const current = mount ? states.entry : states.exit;

                  const transitionState = {
                    transitionStatus,
                    current,
                    mount,
                    ...states,
                  };

                  const exitZindex = exitProps.zIndex || 0;
                  const entryZindex = entryProps.zIndex || 1;

                  return (
                    <TransitionRenderer
                      mount={mount}
                      entryZindex={entryZindex}
                      exitZindex={exitZindex}
                      transitionStatus={transitionStatus}
                      transitionState={transitionState}
                      // injectPageProps={injectPageProps}
                      appearAfter={getMs(appearAfter)}
                    >
                      {children}
                    </TransitionRenderer>
                  );
                }}
              </DelayedTransition>
            </TransitionGroup>
          </div>
        );
      }}
    </Location>
  );
}

export default TransitionHandler;
